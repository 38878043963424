<template>
  <div class="invoice-container">
   电子发票
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang='scss'>
.invoice-container {
}
</style>